// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";

import "trix";
import "@rails/actiontext";
import "chartkick/chart.js";
import "./service-worker-companion";
import "./onlineStatus";
import { initIndexDB } from "./db";

initIndexDB();
