import { Controller } from "@hotwired/stimulus";
import { addRouteDatapoint } from "../../db/routeDatapoints.js";
import { addRouteLocation } from "../../db/routeLocations.js";
import { checkOnlineStatus } from "../../onlineStatus.js";
import {
  getNextDatapointUrl,
  getActiveSanitationRoute,
} from "../../db/sanitationRoutes";
import { serializeForm } from "../../utils.js";
import Mustache from "mustache";

const MINIMUM_ACCURACY = 50;

// Connects to data-controller="route-datapoints--form"
export default class extends Controller {
  static targets = [
    "container",
    "form",
    "lat",
    "lon",
    "pauseButton",
    "submitButton",
    "loadingSpinner",
    "nextIcon",
  ];

  static geolocationOptions = {
    enableHighAccuracy: true,
    maximumAge: 40000,
  };

  async connect() {
    // don't need to wait for online status
    checkOnlineStatus();

    const sanitationRoute = await getActiveSanitationRoute();

    const containerTemplate = this.containerTarget.innerHTML;
    const rendered = Mustache.render(containerTemplate, {
      current_tree_number: sanitationRoute.current_tree_number,
      total_trees_in_route: sanitationRoute.total_trees_in_route,
      current_section_number: sanitationRoute.current_section_number,
      current_sample_number: sanitationRoute.current_sample_number,
      samples_per_tree_section: sanitationRoute.samples_per_tree_section,
      number_of_tree_sections: sanitationRoute.number_of_tree_sections,
    });

    this.containerTarget.innerHTML = rendered;
    this.sanitationRoute = sanitationRoute;

    if (navigator.geolocation) {
      this.tryToGetAccuratePosition({
        triesLeft: 5,
        successCallback: (position) => this.watchSuccess(position),
      });

      // Interval calls
      this.geoInterval = setInterval(() => {
        this.tryToGetAccuratePosition({
          triesLeft: 5,
          successCallback: (position) => this.watchSuccess(position),
        });
      }, 300000);
    } else {
      alert("No es posible obtener la ubicación actual con este dispositivo.");
    }
  }

  disconnect() {
    if (this.geoInterval) {
      clearInterval(this.geoInterval);
    }
  }

  async tryToGetAccuratePosition({
    triesLeft,
    successCallback,
    resolve = null,
    reject = null,
  }) {
    if (triesLeft <= 0) {
      const errorMessage =
        "No es posible obtener una ubicación precisa. Por favor, inténtelo de nuevo. Asegúrate que tu dispositivo tenga activado la opción de alta precision para el GPS";
      if (reject) {
        reject(errorMessage);
      } else {
        alert(errorMessage);
      }
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position.coords.accuracy <= MINIMUM_ACCURACY) {
          successCallback(position);
          if (resolve) {
            resolve(position);
          }
        } else {
          console.log(
            `Low accuracy: ${position.coords.accuracy} meters, tries left: ${
              triesLeft - 1
            }`
          );
          this.tryToGetAccuratePosition({
            triesLeft: triesLeft - 1,
            successCallback,
            resolve,
            reject,
          });
        }
      },
      (error) => {
        this.geoError(error);
        if (reject) {
          reject("Falló la obtención de la ubicación.");
        }
      },
      this.constructor.geolocationOptions
    );
  }

  pauseRoute(e) {
    e.preventDefault();
    const routeLauncherPath = this.pauseButtonTarget.getAttribute("href");
    const confirmText = this.pauseButtonTarget.getAttribute("data-confirm");

    if (confirm(confirmText)) {
      window.location.href = routeLauncherPath;
    }
  }

  async setPosition() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        try {
          this.tryToGetAccuratePosition({
            triesLeft: 5,
            successCallback: (position) => this.geoSuccess(position, resolve),
            resolve,
            reject,
          });
        } catch (error) {
          alert(error);
        }
      } else {
        console.log("asdfas");
        reject(
          "No es posible obtener la ubicación actual con este dispositivo."
        );
      }
    });
  }

  async submit(e) {
    e.preventDefault();
    const sanitationRoute = await getActiveSanitationRoute();
    this.submitButtonTarget.disabled = true;
    this.nextIconTarget.classList.toggle("hidden");
    this.loadingSpinnerTarget.classList.toggle("hidden");

    try {
      await this.setPosition();

      await addRouteDatapoint({
        data: serializeForm(this.formTarget),
        sanitationRouteId: sanitationRoute.id,
        treeNumber: sanitationRoute.current_tree_number,
        questionType: sanitationRoute.current_question_type,
      });

      const nextStepUrl = await getNextDatapointUrl(sanitationRoute.id);
      window.location.href = nextStepUrl;
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      this.submitButtonTarget.disabled = false;
      this.nextIconTarget.classList.toggle("hidden");
      this.loadingSpinnerTarget.classList.toggle("hidden");
    }
  }

  geoSuccess(position, resolve) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    this.latTarget.value = position.coords.latitude;
    this.lonTarget.value = position.coords.longitude;

    resolve({ latitude, longitude });
  }

  async watchSuccess(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;

    await addRouteLocation({
      sanitationRouteId: this.sanitationRoute.id,
      lat: lat,
      lon: lon,
    });
  }

  geoError(error) {
    console.error(`Error occurred. Error code: ${error.code}`);
    // Error codes
    // 1: Permission denied
    // 2: Position unavailable
    // 3: Timeout
    switch (error.code) {
      case 1:
        alert("Permission denied. Please enable location services.");
        break;
      case 2:
        alert("Position unavailable. Please try again.");
        break;
      case 3:
        alert("Request timed out. Please try again.");
        break;
      default:
        alert("An unknown error occurred.");
    }
  }
}
