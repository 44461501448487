import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sanitation-routes--show-map"
export default class extends Controller {
  static targets = ["container"];

  connect() {
    const polygonCoordinates = JSON.parse(this.element.dataset.polygon);
    const routeCoordinates = JSON.parse(this.element.dataset.routeCoordinates);
    const routeTrees = JSON.parse(this.element.dataset.routeTrees);

    const heatmapFieldValues = JSON.parse(
      this.element.dataset.heatmapFieldValues
    );
    const markerFieldValues = JSON.parse(
      this.element.dataset.markerFieldValues
    );
    const textFieldValues = JSON.parse(this.element.dataset.textFieldValues);

    this.map = L.map(this.containerTarget).setView([0, 0], 13);

    const maxZoomLevel = 22;

    L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVhbjI2NyIsImEiOiJjbHpsdjM4dHcwNnJhMmxvamR0eHRqa2c3In0.707gQvzDC0UxlvViBusF-g",
      {
        maxZoom: maxZoomLevel, // Higher max zoom level for Mapbox tiles
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      }
    ).addTo(this.map);

    const polygonLayer = L.geoJSON(polygonCoordinates).addTo(this.map);

    // Center the map on the polygon
    const bounds = polygonLayer.getBounds();
    this.map.fitBounds(bounds);

    // Plot the route coordinates
    if (routeCoordinates.length > 0) {
      L.polyline(routeCoordinates, { color: "green" }).addTo(this.map);
    }

    // Plot the route datapoints
    routeTrees.forEach((routeTree) => {
      const marker = L.marker(routeTree.coordinates).addTo(this.map);

      // Add click event listener to navigate to a different URL after confirmation
      marker.on("click", () => {
        if (confirm("Do you want to navigate to this tree?")) {
          window.location.href = routeTree.showViewUrl;
        }
      });
    });

    // Create heatmap layers for custom field values of type "number", "select_field", "checkbox" and "radio"
    const heatmapLayers = {};
    for (const [customFieldName, values] of Object.entries(
      heatmapFieldValues
    )) {
      const heatmapPoints = values.map(([lat, lon, value]) => [
        lat,
        lon,
        value,
      ]);

      const maxValue = Math.max(...values.map(([, , value]) => value));

      console.log({ maxValue });

      heatmapLayers[`Mapa de calor: ${customFieldName}`] = L.heatLayer(
        heatmapPoints,
        {
          radius: 25,
          max: maxValue,
        }
      ).addTo(this.map);
    }

    // Create marker layers for custom field values of type "checkbox"
    const markerLayers = {};
    for (const [customFieldName, values] of Object.entries(markerFieldValues)) {
      const markers = values.map(({ coordinates, color }) => {
        return L.circleMarker(coordinates, {
          color: color,
          fillOpacity: 1,
        }).bindPopup(customFieldName);
      });

      markerLayers[`Presencia: ${customFieldName}`] = L.layerGroup(
        markers
      ).addTo(this.map);
    }

    // Plot text field values as markers with popups
    textFieldValues.forEach((val) => {
      const marker = L.marker(val.coordinates).addTo(this.map);
      marker.bindPopup(val.value);
    });

    // Add layer controls
    L.control
      .layers(null, { ...heatmapLayers, ...markerLayers })
      .addTo(this.map);

    // Initialize Geoman
    this.map.pm.addControls({
      position: "topleft",
      drawPolygon: false,
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawCircle: false,
      drawRectangle: false,
      editMode: false,
      dragMode: false,
      cutPolygon: false,
      drawText: false,
      removalMode: false,
      editControls: false,
    });
  }

  generateUniqueColors(count) {
    const colors = new Set();
    while (colors.size < count) {
      const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      colors.add(color);
    }
    return [...colors]; // Convert to array for easier indexing
  }
}
