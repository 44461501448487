import { Controller } from "@hotwired/stimulus";
import {
  getSanitationForms,
  putSanitationForm,
} from "../../db/sanitationForms";
import { capitalize } from "../../utils";
import Mustache from "mustache";
import {
  deleteSanitationRoute,
  getCurrentDatapointUrl,
  getInProgressSanitationRoutes,
  getCompletedSanitationRoutes,
} from "../../db/sanitationRoutes";

// Connects to data-controller="route-launcher--index"
export default class extends Controller {
  static targets = [
    "listContainer",
    "sanitationRoutesListContainer",
    "listItemTemplate",
    "inProgressItemTemplate",
    "completedItemTemplate",
    "emptyRoutesTemplate",
    "loadingSpinner",
    "deleteInProgressSanitationRouteButton",
  ];
  static values = {
    sanitationForms: Array,
  };

  async connect() {
    const completedSanitationRoutes = await getCompletedSanitationRoutes();
    const inProgressSanitationRoutes = await getInProgressSanitationRoutes();

    if (
      completedSanitationRoutes.length === 0 &&
      inProgressSanitationRoutes.length === 0
    ) {
      this.sanitationRoutesListContainerTarget.innerHTML =
        this.emptyRoutesTemplateTarget.innerHTML;
    } else {
      await this.renderCompletedSanitationRoutes(completedSanitationRoutes);
      await this.renderInProgressSanitationRoutes(inProgressSanitationRoutes);
    }

    this.renderSanitationForms(await getSanitationForms());
  }

  async downloadAll() {
    this.loadingSpinnerTarget.classList.remove("hidden");
    const downloadPromises = this.sanitationFormsValue.map(async (form) => {
      return Promise.all(
        form.urls.map(async (url) => {
          // Assuming you have a function to handle the download of each URL
          const response = await fetch(url.url);

          if (!response.ok) {
            throw new Error(`Failed to fetch ${url.url}`);
          }
        })
      );
    });

    try {
      await Promise.all(downloadPromises);

      this.sanitationFormsValue.map(async (form) => {
        const sanitationForm = JSON.parse(form.sanitation_form);
        await putSanitationForm({
          id: sanitationForm.id,
          farm_id: sanitationForm.farm_id,
          name: sanitationForm.name,
          description: sanitationForm.description,
          lot_tree_percentage: sanitationForm.lot_tree_percentage,
          updated_at: sanitationForm.updated_at,
        });
      });
      this.renderSanitationForms(await getSanitationForms());
    } catch (error) {
      alert(
        "Un error ocurrió al descargar los formularios, por favor intente de nuevo o contacte a soporte."
      );
      console.error("Error downloading forms:", error);
    } finally {
      this.loadingSpinnerTarget.classList.add("hidden");
    }
  }

  renderSanitationForms(sanitationForms) {
    sanitationForms.forEach(async (form) => {
      const currentItem = this.listContainerTarget.querySelector(
        `#sanitation_form_${form.id}`
      );

      if (currentItem) {
        // replace the current item with renderSanitationForm
        currentItem.outerHTML = this.renderSanitationForm(form);
      } else {
        this.listContainerTarget.innerHTML += this.renderSanitationForm(form);
      }
    });
  }

  async renderInProgressSanitationRoutes(sanitationRoutes) {
    for (const route of sanitationRoutes) {
      const currentItem =
        this.sanitationRoutesListContainerTarget.querySelector(
          `#sanitation_route_${route.id}`
        );

      if (currentItem) {
        // replace the current item with renderSanitationForm
        currentItem.outerHTML = await this.renderInProgressSanitationRoute(
          route
        );
      } else {
        const routeElement = document.createElement("div");
        routeElement.innerHTML = await this.renderInProgressSanitationRoute(
          route
        );
        this.sanitationRoutesListContainerTarget.appendChild(routeElement);
      }
    }
  }

  async renderCompletedSanitationRoutes(sanitationRoutes) {
    for (const route of sanitationRoutes) {
      const currentItem =
        this.sanitationRoutesListContainerTarget.querySelector(
          `#sanitation_route_${route.id}`
        );

      if (currentItem) {
        // replace the current item with renderSanitationForm
        currentItem.outerHTML = await this.renderCompletedSanitationRoute(
          route
        );
      } else {
        const routeElement = document.createElement("div");
        routeElement.innerHTML = await this.renderCompletedSanitationRoute(
          route
        );
        this.sanitationRoutesListContainerTarget.appendChild(routeElement);
      }
    }
  }

  async renderInProgressSanitationRoute(route) {
    const template = this.inProgressItemTemplateTarget.innerHTML;
    const rendered = Mustache.render(template, {
      sanitation_form_name: capitalize(route.sanitation_form_name),
      lot_name: capitalize(route.lot_name),
      user_name: capitalize(route.user_name),
      sanitation_route_id: route.id,
      created_at: new Date(route.created_at).toLocaleString(),
      route_continue_path: await getCurrentDatapointUrl(route.id),
    });
    return rendered;
  }

  async renderCompletedSanitationRoute(route) {
    const template = this.completedItemTemplateTarget.innerHTML;
    const rendered = Mustache.render(template, {
      sanitation_form_name: capitalize(route.sanitation_form_name),
      lot_name: capitalize(route.lot_name),
      user_name: capitalize(route.user_name),
      sanitation_route_id: route.id,
      created_at: new Date(route.created_at).toLocaleDateString("es", {
        month: "long",
        day: "numeric",
      }),
      route_continue_path: await getCurrentDatapointUrl(route.id),
    });
    return rendered;
  }

  async deleteInProgressSanitationRoute(event) {
    const routeId = event.target.closest(".js-delete-button").dataset.routeId;

    if (confirm("¿Estás seguro que deseas eliminar esta ruta?")) {
      await deleteSanitationRoute(Number(routeId));
      const completedSanitationRoutes = await getCompletedSanitationRoutes();
      const inProgressSanitationRoutes = await getInProgressSanitationRoutes();

      document.querySelector(`#sanitation_route_${routeId}`).remove();
      if (
        completedSanitationRoutes.length === 0 &&
        inProgressSanitationRoutes.length === 0
      ) {
        this.sanitationRoutesListContainerTarget.innerHTML =
          this.emptyRoutesTemplateTarget.innerHTML;
      }
    }
  }

  renderSanitationForm(form) {
    const template = this.listItemTemplateTarget.innerHTML;
    const rendered = Mustache.render(template, {
      sanitation_form_name: capitalize(form.name),
      sanitation_form_id: form.id,
      lot_tree_percentage: form.lot_tree_percentage,
      route_start_path: `/farms/${form.farm_id}/sanitation_forms/${form.id}/first_step?sanitation_form_id=${form.id}`,
    });
    return rendered;
  }
}
